<template>
  <div class="account">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <main>
      <section id="account_apply">
        <div class="w1300 layout_container">

          <!-- 內頁主標題 -->
          <div class="main_title_box">
            <router-link
              to="/account/search/points"
              class="main_title_btn main_title_btn-evaluate small"
              target="_blank"
            >
              積分查詢
            </router-link>
            <router-link
              to="/account/search/extension"
              class="main_title_btn main_title_btn-apply small"
              target="_blank"
            >
              展延申請查詢
            </router-link>
            <h3 class="main_title txt-bold">證書展延申請</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <div class="main_description_box">
            <h4 class="main_description_title txt-light_green txt-bold">說明</h4>
            <p class="mg-tb-10">
              本會『居家醫療醫學專科醫師』證書有效期限為六年，期滿每次展延期限為六年。
              申請專科醫師證書有效期限展延，應於專科醫師證書之有效期限六年內，
              參加符合本會專科醫師甄審委員會規定之學術活動或繼續教育課程積分總分達180個學分以上者方可辦理展延，
              其中甲類140點必須參加由居家醫療醫學會主辦之研討活動；乙類40點則由居家醫療醫學會協辦或認可之研討活動。
              逾期未達學分數者，需於1年內補修滿180學分方可換證，且補修學分不計入新證書有效期限。
              如有疑問請洽台灣居家醫療醫學會秘書處04-24360305。
            </p>
          </div>

          <!-- form area -->
          <div class="form_box">
            <form
              id="form_account_apply_extend"
              action=""
              method="post"
              @submit.prevent
            >

              <!-- 基本資料 -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">基本資料</h4>
                </div>
              </div>
              <div class="form_interactive">
                <div class="half">
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">姓名</p>
                    <p class="fixed_info_content">{{ user.name }}</p>
                  </div>
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">會員編號</p>
                    <p class="fixed_info_content">{{ user.memberId }}</p>
                  </div>
                  <div class="fixed_info w33">
                    <p class="fixed_info_title">性別</p>
                    <p class="fixed_info_content">{{ user.gender }}</p>
                  </div>
                </div>
                <div class="half">
                  <div class="fixed_info w50">
                    <p class="fixed_info_title">出生年月日</p>
                    <p class="fixed_info_content">{{ user.birthday }}</p>
                  </div>
                  <div class="fixed_info w50">
                    <p class="fixed_info_title">身分證字號</p>
                    <p class="fixed_info_content">{{ user.id }}</p>
                  </div>
                </div>
                <TextInput
                  class="w50"
                  v-model.trim="user.mobile"
                  :item="inputFormat.mobileInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [/^09\d{8}$/, '手機格式錯誤']
                  ]"
                  :maxlength="10"
                />
                <div class="half">
                  <TextInput
                    class="w50"
                    v-model.trim="user.telePublic"
                    :item="inputFormat.telePublicInput"
                    :maxlength="11"
                  />
                  <TextInput
                    class="w50"
                    v-model.trim="user.teleHome"
                    :item="inputFormat.teleHomeInput"
                    :maxlength="11"
                  />
                </div>
              </div>

              <!-- 服務資料 -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">服務資料</h4>
                </div>
              </div>
              <div class="form_interactive">
                <TextInput
                  class="w50"
                  v-model.trim="user.department"
                  :item="inputFormat.departmentInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                />
                <TextInput
                  class="w50"
                  v-model.trim="user.hospital"
                  :item="inputFormat.hospitalInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                />
                <div class="select_container w100">
                  <p><span class="txt-red txt-regular star_hidden">*</span>院所地址</p>
                  <div class="select_container-left err_message_box w50">
                    <span class="err_message txt-red small">{{ hospitalAddressErrorMsg }}</span>
                    <select
                      v-model="user.addressHospital.town"
                      class="select_city address_top w33"
                      @change="resetHospitalSelect"
                    >
                      <option value="" selected>請選擇</option>
                      <option
                        v-for="(town, index) in hospitalTowns"
                        :key="index"
                        :value="town"
                      >{{ town }}
                      </option>
                    </select>
                    <select
                      v-model="user.addressHospital.area"
                      class="select_city address_top w33"
                      @change="updateHospitalZip"
                    >
                      <option value="" selected>請選擇</option>
                      <option
                        v-for="(area, index) in hospitalAreas"
                        :key="index"
                        :value="area"
                      >{{ area }}
                      </option>
                    </select>
                    <input
                      :value="user.addressHospital.zip"
                      type="text"
                      readonly
                      class="address_top w33"
                      placeholder="郵遞區號"
                    >
                  </div>
                  <div class="select_container-right w50">
                    <TextInput
                      class="address_top"
                      v-model.trim="user.addressHospital.detail"
                      :item="inputFormat.addressHospitalDetailInput"
                    />
                  </div>
                </div>
              </div>

              <!-- 展延事由 -->
              <div class="form_description">
                <div class="main_description_box mg-tb-10">
                  <h4 class="main_description_title txt-light_green txt-bold">展延事由</h4>
                </div>
              </div>
              <div class="form_interactive">
                <Textarea
                  class="w100"
                  :cols="30"
                  :rows="10"
                  v-model="user.postponeReason"
                  :item="inputFormat.postponeReasonTextarea"
                  :rules="[
                    val => !!val || '請輸入展延事由!',
                  ]"
                />
                <Upload
                  class="w100"
                  v-model="user.upload.extensionProof"
                  :item="inputFormat.extensionProofUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />
              </div>

              <div class="form_submit txt-center">
                <button
                  type="button"
                  class="btn p"
                  @click="onSubmit"
                >送出</button>
              </div>

            </form>
          </div>

        </div>
      </section>
    </main>

    <ModalSuccess
      :isDataValid="isDataValid"
      :modalItem="modalItem"
      @closeSuccessModal="closeSuccessModal"
    />
  </div>
</template>

<script>
import cities from '@/data/cities.json';
import PageSubHeader from '@/components/PageSubHeader.vue';
import { AccountApplySubHeader } from '@/lib/const';
import TextInput from '@/components/form/TextInput.vue';
import Textarea from '@/components/form/Textarea.vue';
import Upload from '@/components/form/Upload.vue';
import ModalSuccess from '@/components/ModalSuccess.vue';
import axios from 'axios';

export default {
  name: 'AccountApplyExtension',
  data() {
    return {
      ...AccountApplySubHeader,
      hospitalAddressErrorMsg: '',
      isDataValid: false,
      modalItem: {
        content: '已收到您的申請資料',
        btnText: '確認',
      },
      user: {
        name: '王小明',
        memberId: '009',
        gender: '男',
        birthday: '2020-10-20',
        id: 'A123456789',
        mobile: '0987654321',
        telePublic: '02-12345678',
        teleHome: '02-12345678',
        department: '心臟科',
        hospital: '真心中醫',
        addressHospital: {
          town: '台北市',
          area: '大安區',
          zip: '106',
          detail: '仁愛路四段112巷15號4樓',
        },
        postponeReason: '',
        upload: {
          extensionProof: '',
        },
      },
      inputFormat: {
        mobileInput: {
          label: '手機號碼',
          type: 'text',
          placeholder: '請輸入手機號碼',
          required: true,
        },
        telePublicInput: {
          label: '電話(公)',
          type: 'text',
          placeholder: '02-12345678',
          required: false,
        },
        teleHomeInput: {
          label: '電話(家)',
          type: 'text',
          placeholder: '02-12345678',
          required: false,
        },
        departmentInput: {
          label: '科別',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        hospitalInput: {
          label: '院所名稱(例如：台灣居家醫療醫學會)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        addressHospitalDetailInput: {
          label: '',
          type: 'text',
          placeholder: '請填寫院所地址',
          required: false,
        },
        postponeReasonTextarea: {
          placeholder: '請說明展延事由...',
          required: true,
        },
        extensionProofUpload: {
          label: '證明文件',
          reminder: '檔案大小限制：4MB；檔案類型：zip、doc、docx、pdf',
          accept: '.zip,.doc,.docx,.pdf',
          required: true,
        },
      },
    };
  },
  computed: {
    hospitalTowns() {
      return cities.map((city) => city.name);
    },
    hospitalAreas() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.addressHospital.town);
      if (hospitalTownIndex >= 0) {
        return cities[hospitalTownIndex].areas.map((area) => area.name);
      }
      return [];
    },
  },
  methods: {
    closeSuccessModal() {
      this.isDataValid = false;
    },
    updateHospitalZip() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.addressHospital.town);
      const hospitalAreaIndex = this.hospitalAreas.indexOf(this.user.addressHospital.area);
      if (hospitalAreaIndex >= 0) {
        this.user.addressHospital.zip = cities[hospitalTownIndex].areas[hospitalAreaIndex].zip;
      }
      if (hospitalAreaIndex === -1) {
        this.user.addressHospital.zip = '';
      }
      // this.addressHospitalTest();
    },
    resetHospitalSelect() {
      this.user.addressHospital.area = '';
      this.user.addressHospital.zip = '';
      // this.addressHospitalTest();
    },
    // addressHospitalTest() {
    //   if (!this.user.addressHospital.town || !this.user.addressHospital.area) {
    //     this.hospitalAddressErrorMsg = '必選欄位！請選擇縣市及區域！';
    //   }
    //   if (this.user.addressHospital.town && this.user.addressHospital.area) {
    //     this.hospitalAddressErrorMsg = '';
    //   }
    // },
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        axios.post('https://my-json-server.typicode.com/typicode/demo/posts', this.user)
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            // console.log('success', res.data);
            this.isDataValid = true;
          });
        // .catch((error) => {
        //   console.log(error);
        // });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
  },
  components: {
    PageSubHeader,
    TextInput,
    Textarea,
    Upload,
    ModalSuccess,
  },
};
</script>
