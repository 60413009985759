<template>
  <div class="err_message_box">
    <label class="p" v-show="item.label" :for="id">
        <span class="txt-red txt-regular" v-if="item.required">*</span>
        {{item.label}}
      <!-- <router-link v-if="!!item.link && !isLoggedIn"
        :to="item.link" class="">
        {{item.linkLabel}}
      </router-link> -->
    </label>
    <span class="err_message txt-red small">{{errorMsg}}</span>
    <input
      :id="id"
      :value="value"
      :rules="rules"
      :type="item.type"
      :error="error"
      :maxlength="maxlength"
      :placeholder="item.placeholder"
      @blur="test()"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    value: {},
    type: {
      type: String,
      default: 'text',
    },
    rules: Array,
    maxlength: Number,
    // label: String,
    placeholder: String,
    item: {
      placeholder: String,
      label: String,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorMsg: '',
      id: null,
    };
  },
  watch: {
    value(v) {
      this.errorMsg = this.validate(v) ? this.validate(v) : '';
    },
  },
  computed: {
    hasRules() {
      return this.rules !== undefined
        && this.rules !== null
        && this.rules.length > 0;
    },
    error() {
      if (this.hasRules && this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  methods: {
    test() {
      this.errorMsg = this.validate(this.value) ? this.validate(this.value) : '';
    },
    validate(val = this.value) {
      if (this.hasRules === true) {
        const errorMessage = [];
        this.rules.forEach((rule) => {
          if (typeof rule === 'function') {
            if (rule(val) !== true) {
              errorMessage.push(rule(val));
            }
          } else if (typeof rule === 'object') {
            if (!val.match(rule[0])) {
              errorMessage.push(rule[1]);
            }
          }
        });
        if (errorMessage.length) {
          return errorMessage[0];
        }
      }
      return '';
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid;
  },
};
</script>
