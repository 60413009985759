<template>
  <label :for="id" class="err_message_box">
    <span class="err_message txt-red small">{{errorMsg}}</span>
    <textarea
      :id="id"
      :cols="cols"
      :rows="rows"
      :value="value"
      :rules="rules"
      :error="error"
      :placeholder="item.placeholder"
      @blur="test()"
      @input="$emit('input', $event.target.value)"
    ></textarea>
  </label>
</template>

<script>
export default {
  name: 'FormTextarea',
  props: {
    cols: Number,
    rows: Number,
    item: {},
    value: {},
    rules: Array,
  },
  data() {
    return {
      errorMsg: '',
      id: null,
    };
  },
  watch: {
    value(v) {
      this.errorMsg = this.validate(v) ? this.validate(v) : '';
    },
  },
  computed: {
    hasRules() {
      return this.rules !== undefined
        && this.rules !== null
        && this.rules.length > 0;
    },
    error() {
      if (this.hasRules && this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  methods: {
    test() {
      this.errorMsg = this.validate(this.value) ? this.validate(this.value) : '';
    },
    validate(val = this.value) {
      if (this.hasRules === true) {
        const errorMessage = [];
        this.rules.forEach((rule) => {
          if (typeof rule === 'function') {
            if (rule(val) !== true) {
              errorMessage.push(rule(val));
            }
          } else if (typeof rule === 'object') {
            if (!val.match(rule[0])) {
              errorMessage.push(rule[1]);
            }
          }
        });
        if (errorMessage.length) {
          return errorMessage[0];
        }
      }
      return '';
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid;
  },
};
</script>
